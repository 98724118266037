
// Libraries
import * as React from 'react'
import { graphql, Link } from 'gatsby'
import moment from 'moment'

// Services
import StaticMapService from '../services/staticMap'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import EventList from "../components/eventList"
import Apply from '../components/apply'
import EventType from '../components/eventType'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrowser, faConciergeBell, faDirections, faShieldCheck, faEnvelope } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

const EventTemplate = ({ data }) => {
	const mapper = new StaticMapService()
	const mapUrl = mapper.getImageUrl(data.event)
	const mapLink = mapper.getLink(data.event)

	return (
		<Layout> 			
			<Seo
				title={`${data.event.artist.name} - ${data.event.name}`}
				description={data.event.synopsis}
				image={data.event.image}
				event={data.event}
				custom={true}
				useEventsJsonLd
			/>

			<section itemScope itemType="https://schema.org/Event">
				<Hero contentFullWidth={true} bg="event">
					<div className="event-hero">
						<h1 className="event-hero__main-heading" itemProp="name">{data.event.artist.name}</h1>

						<div className="event-hero__overview">
							<div className="event-hero__overview__breadcrumb">
								<ul>
									<li>
										<Link to={"/events"}>Events <FontAwesomeIcon icon={faArrowRight} /></Link>
									</li>

									{data?.event?.artist?.name &&
										<li>
											{data.event.artist.name}
										</li>
									}
								</ul>
							</div>

							<div className="event-hero__fields">
								{(data?.event?.artist?.name || data?.event?.performer_name) &&
									<div className="event-hero__fields__subheading" itemProp="performer" itemType="https://schema.org/PerformingGroup">
										{data?.event?.artist?.name || data?.event?.performer_name}
									</div>
								}

								{data?.event?.name &&
									<div className="event-hero__fields__performer">{data.event.name}</div>
								}

								{data?.event?.type &&
									<div className="event-hero__fields__type">
										<EventType data={data.event} />
									</div>
								}

								{data?.event?.venue?.name &&
									<div className="event-hero__fields__venue" itemScope itemProp="location"  itemType="https://schema.org/Place">
										<span itemProp="name">{data.event.venue.name}</span>
									</div>
								}

								{data?.event?.starts &&
									<div className="event-hero__fields__date" itemProp="startDate" content={moment(data.event.starts).toISOString()}>
										{moment(data.event.starts).format('MMMM D, Y [at] HH:mm')}<br/>
										<small>Doors open at {moment(data.event.door_times).format('HH:mm')}</small>
									</div>
								}

								<Apply event={data?.event} />
							</div>

							{data?.event?.image_gatsby &&
								<div className="event-hero__image">
									<div className="event-hero__image__container">
										<GatsbyImage image={data.event.image_gatsby.childImageSharp.gatsbyImageData}
													 alt={`${data.event.artist.name} - ${data.event.name}`} />
									</div>
								</div>
							}
						</div>
					</div>
				</Hero>

				<div className="event-information">
					<div className="container">
						<div className="event-information__contain">
							{mapLink && mapUrl &&
								<div className="event-information__contain__map">
									<a href={mapLink} target="blank" rel="noreferrer">
										<img src={mapUrl} width={800} height={480}
											 alt={'Google Map for ' + data?.event?.venue?.name} loading="lazy"/>
									</a>
								</div>
							}

							<div className="event-information__contain__detail">
								<h2>Event Information</h2>
								<p>{data?.event?.synopsis}</p>

								<ul className="event-information__contain__detail__links">
									{data?.event?.venue?.website_url ?
										<li>
											<a href={data.event.venue.website_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faBrowser} /> <span>Venue Website</span></a>
										</li>
									: ''}

									{data?.event?.venue?.directions_url ?
										<li><a href={data.event.venue.directions_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faDirections} /> <span>How to get here</span></a></li>
										: ''}

									{data?.event?.venue?.customer_service_email ?
										<li><a href={'mailto:' + data.event.venue.customer_service_email}><FontAwesomeIcon icon={faEnvelope} /> <span>Customer Service Email</span></a></li>
										: ''}

									{data?.event?.venue?.customer_service_url ?
										<li><a href={data.event.venue.customer_service_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faConciergeBell} /> <span>Customer Service Link</span></a></li>
										: ''}

									{data?.event?.venue?.security_information_url ?
										<li><a href={data.event.venue.security_information_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faShieldCheck} /> <span>Security Information</span></a></li>
										: ''}
								</ul>
							</div>
						</div>
					</div>

					<div className="event-information__circles">
						<div>&nbsp;</div>
						<div>&nbsp;</div>
						<div>&nbsp;</div>
						<div>&nbsp;</div>
						<div>&nbsp;</div>
						<div>&nbsp;</div>
						<div>&nbsp;</div>
					</div>
				</div>
			</section>

			<section className="event-related">
				<div className="container">
					<h3>Latest Events</h3>
				</div>

				<EventList />
			</section>
		</Layout>
	)
}

// Query GraphQL for an event with matching apiId to pageContext
export const query = graphql`
	query ($apiId: String) {
    	event(apiId: {eq: $apiId}) {
      		id
      		origId
      		name
      		synopsis
		    image_gatsby {
				childImageSharp {
					gatsbyImageData(
						width: 1860,
						aspectRatio: 1.78,
						layout: CONSTRAINED,
					)
				}
		    }
		    artist {
				name
			}
		    application_deadline_raw: application_deadline
		    application_deadline
		    venue {
				name
				security_information_url
				customer_service_email
				customer_service_url
				directions_url
				website_url
				addresses {
					street
					state
					city
					postal_code
				}
		    }
		    max_tickets_per_transaction
      		starts
      		door_times
      		ends
      		geo_locked
      		active_geo_lock
    	}
  	}
`

export default EventTemplate
