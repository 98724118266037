
// Libraries
import * as React from 'react'
import {createRef, useContext, useEffect, useState} from 'react'
import {navigate} from 'gatsby'

// Services
import ApiService from '../services/api'

// Contexts
import BasketContext from '../contexts/basket'
import NotificationContext from '../contexts/notification'

// Components
import TicketAdder from './ticketAdder'

// Animations
import { Player } from '@lottiefiles/react-lottie-player'
import buttonJson from '../animations/buttonAddToBasket.json'

const AddToBasket = (props) => {
	const [added, setAdded] = useState(false);
	const [basketItem, setBasketItem] = useState();
	const [num, setNum] = useState(1);

	const onChange = (newNum) => {
		setNum(newNum)
	}

	const addToBasket = async () => {
		const api = new ApiService();
		const body = {
			item_id: props.event.origId,
			item_type: 'App\\Models\\Event',
			quantity: num
		};

		const response = await api.put('basket', {body: JSON.stringify(body)});

		if (response.success) {
			if (typeof window !== 'undefined') {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					'event': 'basket_add',
					'basket_item_id': props.event.id,
					'basket_item_name': props.event.artist.name + ' - ' + props.event.name,
					'basket_item_value': 4.5
				});
			}

			update(response.data)
			setAdded(true)

			buttonLottie.loop = false
			buttonLottie.playSegments([[49, 114], [115, 116]], true)

			setTimeout(() => {
				setButtonLottieState('toBasket')
			}, 2000)
		} else {
			const message = response.errors.basket[0] ? response.errors.basket[0] : 'There was an error adding your tickets to your basket.';
			addNotification(message, 'error');

			setButtonLottieState('error') // button can't be clicked
			buttonLottie.loop = false
			buttonLottie.playSegments([[159, 166], [167, 168]], true)
		}
	}

	const { update, hasItem } = useContext(BasketContext);
	const { addNotification } = useContext(NotificationContext);

	let buttonLottiePlayer = createRef()
	const [buttonLottie, setButtonLottie] = useState(null)
	const [buttonLottieState, setButtonLottieState] = useState(null)

	const buttonLottieHover = () => {
		if (num > 0 && !buttonLottieState) {
			buttonLottie.playSegments([[buttonLottie.currentFrame, 16], [17, 18]], true)
		}
		else if (buttonLottieState === 'toBasket') {
			buttonLottie.playSegments([[116, 132], [133, 134]], true)
		}
	}

	const buttonLottieBlur = () => {
		if (num > 0 && !buttonLottieState) {
			buttonLottie.playSegments([[18, 1], [1, 0]], false)
		}
		else if (buttonLottieState === 'toBasket') {
			buttonLottie.playSegments([[134, 118], [117, 116]], false)
		}
	}

	const buttonLottieClick = () => {
		if (num > 0 && !buttonLottieState) {
			setButtonLottieState('submitting')
			buttonLottie.loop = true
			buttonLottie.playSegments([21, 49], true)
			addToBasket()
		}
		else if (buttonLottieState === 'toBasket') {
			buttonLottie.playSegments([139, 160], true)
			setTimeout(() => {
				navigate('/basket')
			}, 500)
		}
	}

	useEffect(() => {
		setBasketItem(hasItem(parseInt(props.event.origId)))
	})

	return (
		<div className="add-to-basket">
			{basketItem
				? <div className="add-to-basket__success">
					<p>{added
						? 'Great! You have added ' + num + ' ' + ((num > 1) ? 'tickets' : 'ticket') + ' to your basket.'
						: 'You already have tickets for this ballot in your basket.'}</p>
				</div>
				: <TicketAdder max={props.event.max_tickets_per_transaction} initial='1' min='1' onChange={onChange} />
			}
			<button className='btn btn--lottie-wrap' disabled={num === 0} onMouseEnter={buttonLottieHover}
					onMouseLeave={buttonLottieBlur} onClick={buttonLottieClick}>
				<span className='sr-only'>Add to Basket</span>
				<Player
					src={buttonJson}
					ref={buttonLottiePlayer}
					lottieRef={(instance) => {
						setButtonLottie(instance)
					}}
					autoplay={false}
					loop={false}
					controls={false} />
			</button>
		</div>
	)
}

export default AddToBasket
