
// Libraries
import * as React from 'react'
import moment from 'moment'

// Services
import AuthService from "../services/auth"
import EventService from "../services/event"

// Components
import Button from "./button"
import AddToBasket from "./addToBasket"

const authService = new AuthService()

class Apply extends React.Component {

	state = {
		isLoggedIn: null,
		isGeoLocked: false,
		isDeadlineInTheFuture: true
	}

	async componentDidMount() {
		this.isDeadlineInTheFuture()

		const isLoggedIn = await authService.isLoggedIn()
		this.setState({isLoggedIn})

		if (isLoggedIn && this.props.event.geo_locked) {
			const eventService = new EventService()
			const eventLive = await eventService.get(this.props.event.origId)
			this.setState({isGeoLocked: eventLive.data?.active_geo_lock})
		}
	}

	isDeadlineInTheFuture() {
		const dt = new Date(this.props.event.application_deadline_raw)
		const now = new Date()
		this.setState({isDeadlineInFuture: dt.getTime() > now.getTime()})
	}

	render() {
		const {isLoggedIn, isGeoLocked, isDeadlineInTheFuture} = this.state

		return (
			<div className="apply">
				<span className="apply__last-date">Last date to apply: {moment(this.props.event.application_deadline).format('MMMM D, Y')}
				</span>
				<div className="apply__cta">
					{!isGeoLocked
						? <>
							{isDeadlineInTheFuture
							? <>
								{isLoggedIn
									? <AddToBasket event={this.props.event} />
									: <>
										<div className="event-hero__apply__loginrequired">Please login to Concerts for Carers <br/> to
											apply for tickets to this event
										</div>
										<div className="apply__cta__buttons">
											<div className="apply__cta__buttons__btn--login">
												<Button to="/login">Login</Button>
											</div>
											<div className="apply__cta__buttons__btn--register">
												<Button to="/register">Register</Button>
											</div>
										</div>
									</>}
							</>
							: <p className="apply__cta__deadline-passed">The application deadline has passed, so unfortunately
								you can no longer apply for tickets to this event</p>}
						</>
						: <p className="apply__cta__deadline-passed">This event is out of range for your postcode, so unfortunately
							you can't apply for tickets to this event</p>}
				</div>
			</div>
		)
	}
}

export default Apply
