
const MAP_URL_PREFIX = 'https://maps.googleapis.com/maps/api/staticmap';
const GOOGLE_MAPS_PREFIX = 'https://www.google.co.uk/maps/';

/**
 * A very basic service to return a map URL from an event object
 */
export default class StaticMapService {
	getAddress(event) {
		const address = event.venue?.addresses[0];
		if (address) {
			return [
				address.street,
				address.state,
				address.city,
				address.postal_code
			].filter((el) => {
				return el != null;
			})
		}

		return []
	}

	getLink(event) {
		return GOOGLE_MAPS_PREFIX + '?q=' + this.getAddress(event).join(', ');
	}

	getImageUrl(event) {
		const addressStr = this.getAddress(event).join(', ');
		let url = MAP_URL_PREFIX;

		url += '?center=' + addressStr;
		url += '&zoom=13';
		url += '&size=800x480';
		url += '&maptype=roadmap';
		url += '&markers=color:%23005EB8%7C' + addressStr;
		url += '&key=AIzaSyA9-XQeP8dhBzp1e-kIGPEuvSmkuHNpjyA';

		return url;
	}
}